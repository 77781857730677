<template>
  <div class="home">
    <div style="margin-top:100px;text-align: center;font-size: 18px">
      当前在线用户数<b class="color-error">{{userCount}}</b>人
    </div>
    <div v-if="plan != null" style="margin-top:50px;font-size: 26px;text-align:center;font-weight: bold;">
      感谢您的关注，本次答题活动已经结束！
      <div style="font-size: 24px">
        答题{{ plan.passScore }}分以上者请点击 【
        <el-button type="text" style="font-size: 24px;font-weight: bold" @click="download">下载</el-button>
        】学时证明！
      </div>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  name: 'Home',
  extends: BaseVue,
  components: {
  },

  data() {
    return {
      userCount: 0,
      //2023年水利安全知识竞赛
      planId: null,
      plan: null
    }
  },

  created() {
    this.postForm("/user/online-users").then(dr => {
      this.userCount = dr.msg
    });
    if(this.planId) {
        this.loading = true;
        this.postForm("/exam-plan/getById", {
            id: this.planId
        }, true).then(dr => {
            this.plan = dr.bean;
        }).finally(dr => {
            this.loading = false;
        });
    }
  },

  methods: {
    download() {
      this.postForm("/login/checkLogin").then(dr => {
        this.postForm("/learn-hour-record/have-card", {
          planId: this.planId
        }).then(dr => {
          window.open(this.globalConfig.apiRoot + "/learn-hour-record/download?planId=" + this.planId);
        }).catch(dr => {
          this.$alert("您未获得学时，无法下载", {
            type: "error"
          });
        }).finally(dr => {
          this.loading = false;
        });
      }).catch(dr => {
        this.$router.push({
          path: "/login",
          query: {
            planId: this.planId,
            redirect: "/"
          }
        })
      });
    }
  }
}
</script>
